.App {
  text-align: center;
}

canvas{
  width:100%!important;
  height: 100%!important;
}

.typing{
  margin-top: -300px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background: rgb(36,12,0);
  background: linear-gradient(90deg, rgba(36,12,0,1) 0%, rgba(46,57,159,1) 56%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
